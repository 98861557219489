import React from "react";
import { Link } from "react-router-dom";

function SubMenu({
  subLinkItemClassname,
  closeNav,
  subMenuItems,
  subMenuTitleName,
  subMenuTitleClassName,
  subMenuTitleOnClick,
}) {
  const displaySubmenuItems = (submenuItemsToMap) => {
    return submenuItemsToMap.map((submenuItem) => {
      const { link, displayName } = submenuItem;
      return (
        <li key={displayName}>
          <Link
            to={link}
            className={subLinkItemClassname}
            onClick={closeNav}
            key={displayName}
          >
            {displayName}
          </Link>
        </li>
      );
    });
  };
  
  return (
    <ul className="submenu">
      <li>
        <span
          key={subMenuTitleName}
          className={subMenuTitleClassName}
          onClick={subMenuTitleOnClick}
        >{subMenuTitleName}</span>
      </li>
      {displaySubmenuItems(subMenuItems)}
    </ul>
  );
}
export default SubMenu;
