import React from "react";
import "../../styles/container-with-navbar.css"
import Tutors from "./Tutors";
import Meditation from "./Meditation";
import Products from "./Products";
import History from "./History";
import ContainerWihNavbar from "../reuseable/ContainerWihNavbar";

function AboutUs() {
  return (
    <div className="page-wrapper">
      <h1>Rólunk</h1>
      <ContainerWihNavbar>
        <Tutors title="Előadóink" />
        <History title="Történetünk" />
        <Meditation title="Meditáció" />
        <Products title="Kiadványok" />
      </ContainerWihNavbar>
    </div>
  );
}

export default AboutUs;
