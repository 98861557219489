import React from "react";
import { Link } from "react-router-dom";
import { BASIC_INFO } from "../../resources/BasicInfo";
import { mapTematics } from "../../utils/TextUtils";
import { stringFragmenter } from "../../utils/TextUtils";
import { ACTIVE_COURSE } from "../../resources/ActiveCourse";

function CourseCard() {
  const ADDITIONAL_INFORMATION = `A bevezető kurzusok jelenleg online folynak, ezért bárhonnan csatlakozhatsz a csoporttalálkozóhoz megfelelő számítógép vagy mobileszköz használatával. Bár az a legideálisabb, ha az első találkozó alkalmával kezdesz, ha lekésted a kezdési dátumot, még jelentkezhetsz, és felvesszük veled a kapcsolatot.
  Technikai szintű szükségletek: Az online találkozók a Zoom applikáción keresztül zajlanak, így minden olyan számítógép vagy okoseszköz megfelelő, amely a Zoom futtatására alkalmas. Emellett megfelelő sávszélességű internetkapcsolat szükséges.`;

  const weeklyText = `${ACTIVE_COURSE.courseType} hetente ${ACTIVE_COURSE.dayName} ${ACTIVE_COURSE.formattedTime}-tól.`;
  const additionalInfoWithParagraphs = stringFragmenter(ADDITIONAL_INFORMATION);

  return (
    <div>
      <hr></hr>
      <h2>
        Legközelebb induló kurzusunk<br></br>
        {ACTIVE_COURSE.name}
      </h2>
      <hr></hr>
      <div className="info-wrapper">
        <p>{weeklyText}</p>
        <p>{ACTIVE_COURSE.longDesc}</p>
        <p>
          A kurzus kezdési időpontja: <b>{ACTIVE_COURSE.formattedDate}</b>
        </p>
        <h3>A kurzus tematikája</h3>
        <div className="divider-line"></div>
      </div>
      {mapTematics(ACTIVE_COURSE.tematics)}
      <div className="info-wrapper">
        <h3>További információ</h3>
        <div className="divider-line"></div>
        <p>{additionalInfoWithParagraphs}</p>
        <h3>Részvételi hozzájárulás</h3>
        <div className="divider-line"></div>
        <p>
          A szemeszterekért az iskola <strong>adományt</strong> (hozzájárulást a
          működési költségeihez) <strong>fogad</strong> a következő
          bankszámlaszámon: {BASIC_INFO.legalInfo[1].data}.<br></br>
          <strong>A megjegyzés rovatba CSAK annyit írj: adomány</strong>
        </p>
        <p>
          Kérjük, hogy hozzájárulásod összegét saját belátásod és lehetőségeid
          szerint a következő háromból válaszd meg: 15 000 Ft / 20 000 Ft / 25
          000 Ft
        </p>
        <Link to={`/jelentkezes`} className="reusable-button-link">
          Jelentkezem a tanfolyamra
        </Link>
      </div>
    </div>
  );
}
export default CourseCard;
