import FirstCoverImage from "../assets/images/carousel-images/DSC_0174.JPG";
import SecondCoverImage from "../assets/images/carousel-images/DSC_0828.JPG";
import ThirdCoverImage from "../assets/images/carousel-images/DSC_1271.JPG";


export const CAROUSEL_ITEMS = [
  {
    coverImg: FirstCoverImage,
    headline: "Kurzusaink",
    linkPath: "/kurzusok",
    text: `A bölcsesség boldogan leélt teljes életet tesz lehetővé. Kurzusaink egymásra épülnek, a dialektika módszerét használjuk, így nem csak hallgatója, hanem aktív résztvevője is lehetsz a találkozóknak, legyenek online vagy az iskolában tartva.
    A valódi fejlődéshez persze nem egyetlen három hónapos kurzus vezet, a beszélgetések katalizátoraként szolgáló gondolatokat és eszméket egy olyan rendszerbe szedtük össze, amelyek mentén akár évtizedekig is haladhatsz velünk.`,
  },
  {
    coverImg: SecondCoverImage,
    headline: "Történetünk",
    linkPath: "/rolunk/tortenetunk",
    text: `Az iskola története egészen az 1930-as évekig nyúlik vissza, amikor Londonban megalapították az anyaiskola elődjét.
    Az iskola szellemiségét kezdetben az ógörög klasszikusok és reneszánsz gondolkodók, majd Uszpenszkij, Gurdzsijev gondolatai és Maharisi Mahes jógi formálták.
    A magyar iskola 2003-ban alapult.`,
  },
  {
    coverImg: ThirdCoverImage,
    headline: "Vedd fel a kapcsolatot!",
    linkPath: "/kapcsolat",
    text: `Kérdésed van? Vedd fel velünk a kapcsolatot!`,
  },
];
