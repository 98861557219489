import React, { useState } from "react";

function ContainerWihNavbar({ children }) {
  const [selectedElementIndex, setSelectedElementIndex] = useState(0);

  const handleElementChange = (index) => {
    setSelectedElementIndex(index);
  };

  return (
    <div className="main-container">
      <div className="sub-navbar">
        {children.map((child, index) => (
          <h4 
            key={index}
            className={index === selectedElementIndex ? "sub-navbar-btn active" : "sub-navbar-btn"}
            onClick={() => handleElementChange(index)}
          >
            {child.props.title}
          </h4>
        ))}
      </div>
      <div className="element-container">{children[selectedElementIndex]}</div>
    </div>
  );
}
export default ContainerWihNavbar;
