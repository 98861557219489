import FacebookIcon from "../assets/svg/facebook.svg";
import InstagramIcon from "../assets/svg/instagram.svg";
import YoutubeIcon from "../assets/svg/youtube.svg";

export const MENU_ITEMS = [
  { link: "/kurzusok", displayName: "Kurzusok" },
  {
    link: "/rolunk",
    displayName: "Rólunk",
    subItems: [
      { link: "/rolunk/eloadoink", displayName: "Előadóink" },
      { link: "/rolunk/tortenetunk", displayName: "Történetünk" },
      { link: "/rolunk/meditacio", displayName: "Meditáció" },
      { link: "/rolunk/kiadvanyok", displayName: "Kiadványok" },
    ],
  },
  { link: "/blog", displayName: "Blog" },
  { link: "/kapcsolat", displayName: "Kapcsolat" },
  { link: "/egy-szazalek", displayName: "1%" },
  { filler: true, key: "filler1" },
];

export const ICON_ITEMS = [
  {
    external: true,
    link: "https://www.facebook.com/filozofia.hu",
    displayName: "Facebook",
    component: FacebookIcon,
  },
  {
    external: true,
    link: "https://www.instagram.com/filozofia.hu/",
    displayName: "Instagram",
    component: InstagramIcon,
  },
  {
    external: true,
    link: "https://www.youtube.com/@filozofiahu",
    displayName: "Youtube",
    component: YoutubeIcon,
  },
];
