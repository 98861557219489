export const getFromCookies = (itemToGet) => {
  const cookies = document.cookie
    .split(";")
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith(`${itemToGet}=`));

  if (cookies) {
    const cookieValue = cookies.substring(itemToGet.length + 1);
    return JSON.parse(decodeURIComponent(cookieValue));
  }

  return null;
};

export const setCookie = (itemToSet, itemValue, expirationDays = 365) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + expirationDays);

  const encodedValue = encodeURIComponent(JSON.stringify(itemValue));
  const cookie = `${itemToSet}=${encodedValue}; expires=${expirationDate.toUTCString()}; path=/; SameSite=None; Secure`;
  document.cookie = cookie;
};
