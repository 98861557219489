import enAzVagyokImage from "../assets/images/products/en-az-vagyok-konyv2.jpg"
import joTarsasagImage from "../assets/images/products/jo-tarsasag-konyv.jpg"

export const PRODUCTS_LIST = [
  {
    productName: "ÉN AZ VAGYOK",
    productDescription: `Iskolánk és a Filosz Kiadó együttműködéséből megszületett a sokak által régóta várt „I Am That” című könyv magyar kiadása, amely az Én Az vagyok címet kapta. A könyv azon beszélgetések feljegyzése, amelyek az 1981-ben elhunyt, kortárs advaita tanító, filozófus Srí Niszargadatta Mahárádzs és a hozzá évtizedeken keresztül ellátogatók közt zajlott. Niszargadatta olyan tanító, aki semmiféle ideológiát vagy vallást nem hirdetett, hanem az ember valódi természetére igyekezett ráébreszteni az önismeretre törekvőket. Az Én Az vagyok üzenete ezért egyszerű, közvetlen, egyszersmind magasztos.
    „A kereső az, aki önmagát kutatja. Hagyj fel minden kérdéssel, egy kivételével: „Ki vagyok én?”Végtére is az egyetlen tény, amelyről biztos lehetsz az, hogy vagy. A „vagyok” bizonyos. Az, hogy „ez vagyok” nem az. Igyekezz felfedezni, hogy mi vagy valójában! Ahhoz, hogy megtudd mi vagy, először meg kell vizsgálnod, és tudnod kell, hogy mi nem vagy.”
    Srí Niszargadatta Mahárádzs`,
    productImage: enAzVagyokImage,
    shopUrl: "https://www.libri.hu/konyv/maharadzs_sri_niszargadatta.en-az-vagyok.html"
  },{
    productName: "Jó társaság",
    productDescription: `Beszélgetések Őszentsége Sántánanda Szaraszvatíval
    „Ha az ember nem pihenhet szeretetben és boldogságban, nem élhet sokáig, mint ahogy a test sem tud sokáig meglenni alvás nélkül. Ezt a pihenést a meditáció biztosítja. Ahhoz, hogy az embereket eljuttassuk a boldogsághoz, egyszerűen pihenést kell biztosítanunk számukra – pihenést az Énjükkel – hogy életüknek új és friss hajnalai lehessenek.”
    A Jó társaság ezen kiadása elsőként teszi elérhetővé magyar nyelven korunk egyik nagy tanítója, Őszentsége Sántánanda Szaraszvatí útmutatásait.
    Ez a gyűjtemény Őszentségével 1961 és 1985 között folytatott beszélgetések részleteit tartalmazza: tanmesék, történetek és kérdésekre adott válaszok formájában. Tanácsait az advaita filozófia évezredekre visszanyúló kincsestárából merítette, a világ megértését kereső XXI. századi európai ember számára is követhető irányt mutatva.
    Őszentsége Sántánanda Szaraszvatí Észak India Sankarácsárja volt 1953-tól 1980-ban történő visszavonulásáig. Munkásságával nagyban hozzájárult ahhoz, hogy a mantrameditálás a különböző vallási hagyományban élő emberek számára világszerte elérhetővé és gyakorolhatóvá váljon.`,
    productImage: joTarsasagImage,
    shopUrl: "https://www.filosz.hu/konyvek/jo-tarsasag/"
  }
];
