import React from "react";
import "../../styles/main-points.css";
import { stringFragmenter } from "../../utils/TextUtils";

function MainPointCard({ point , evenOrOddClassName}) {
  const textWithParagraphs = stringFragmenter(point.text);

  return (
    <div className={`main-point-card-container ${evenOrOddClassName}`}>
      <div className="main-point-text-container">
        <h2>{point.title}</h2>
        <p>{textWithParagraphs}</p>
      </div>
      <div className="main-point-image-container">
        <img loading="lazy" src={point.image} alt={point.title} className="main-point-card-image"/>
      </div>
    </div>
  );
}
export default MainPointCard;
