import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  FTextInput,
  CustomPasswordInput,
  ADMIN_LOG_IN_FORM_ITEMS,
  VALIDATION_MESSAGES,
} from "../../../resources/FormResources";
import "../../../styles/forms.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setAuthenticationStatus,
  setToken,
  setAdminName,
} from "../../../redux/slice/adminSlice";
import { authLogin } from "../../../services/ApiServices";
import { scrollToTop } from "../../../utils/WindowUtils";
import LoginErrorMessage from "./LoginErrorMessage";

function AdminLogin() {
  const [errorMessage, setErrorMessage] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const fInitValues = ADMIN_LOG_IN_FORM_ITEMS.reduce((result, obj) => {
    return { ...result, [obj.itemName]: obj.initialValue };
  }, {});

  const fValidationSchema = ADMIN_LOG_IN_FORM_ITEMS.reduce((result, item) => {
    if (item.inputType === "email") {
      return {
        ...result,
        [item.itemName]: Yup.string()
          .email(item.regExpWarning)
          .required(VALIDATION_MESSAGES.required),
      };
    }
    if (item.inputType === "password") {
      return {
        ...result,
        [item.itemName]: Yup.string()
          .required(VALIDATION_MESSAGES.required)
          .max(item.max, VALIDATION_MESSAGES.max)
          .min(item.min, VALIDATION_MESSAGES.min)
          .matches(item.regExp, item.regExpWarning),
      };
    }
    return {}; //just to get rid of the warning message
  }, {});

  const formInputs = ADMIN_LOG_IN_FORM_ITEMS.map((item) => {
    if (item.inputType === "email") {
      return (
        <FTextInput
          key={item.itemName}
          label={item.label}
          name={item.itemName}
          type="email"
        />
      );
    }

    if (item.inputType === "password") {
      return (
        <CustomPasswordInput
          key={item.itemName}
          label={item.label}
          name={item.itemName}
          type="text"
        />
      );
    }

    return <></>;
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (credentials, setSubmitting) => {
    setIsButtonDisabled(true);

    try {
      const response = await authLogin(credentials);
      dispatch(setAuthenticationStatus(true));
      dispatch(setAdminName(credentials.email));
      dispatch(setToken(response.data.token));
      setSubmitting(false);
      navigate("/view-members");
    } catch (error) {
      setSubmitting(false);
      if (error?.response?.status) {
        setErrorMessage(error.response.status);
        scrollToTop();
        return;
      }
      setErrorMessage("Unkown error");
      scrollToTop();
    }
  };

  return (
    <div className="page-wrapper">
      <h1>Admin Login</h1>
      {errorMessage ? (
        <LoginErrorMessage errorMessage={errorMessage} />
      ) : (
        <Formik
          initialValues={fInitValues}
          validationSchema={Yup.object(fValidationSchema)}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting);
          }}
        >
          <Form>
            {formInputs}
            <button type="submit" disabled={isButtonDisabled} className={`submit-btn ${isButtonDisabled ? "disabled-btn" : ""}`}>
              Login
            </button>
          </Form>
        </Formik>
      )}
    </div>
  );
}
export default AdminLogin;
