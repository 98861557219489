import React from "react";
import "../../styles/main-points.css";
import { MAIN_POINTS } from "../../resources/HomeMainPoints";
import MainPointCard from "./MainPointCard";

function MainPoints() {
  const displayMainPoints = MAIN_POINTS.map((point, index) => (
    <MainPointCard
      key={index}
      point={point}
      evenOrOddClassName={
        index % 2 === 0
          ? "main-point-card-container-even"
          : "main-point-card-container-odd"
      }
    />
  ));

  return <div className="main-points-container">{displayMainPoints}</div>;
}
export default MainPoints;
