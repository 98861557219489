import React from "react";
import "../../styles/reusable-card.css";
import { stringFragmenter } from "../../utils/TextUtils";

function ProductCard({ product }) {
  const descWithParagraphs = stringFragmenter(product.productDescription);

  return (
    <div className="reusable-card">
      <div className="reusable-card-name">{product.productName}</div>
      <div className="divider-line"></div>
      <div className="reusable-card-details">
        <div>
          <div>
            <a href={product.shopUrl} target="_blank" rel="noopener noreferrer">
              <img
                src={product.productImage}
                alt={product.productName}
                className="reusable-card-image"
              />
            </a>
          </div>
          <p className="reusable-card-description">{descWithParagraphs}</p>
          <a
            className="reusable-button-link"
            href={product.shopUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Megnyitás webshopban
          </a>
        </div>
      </div>
    </div>
  );
}
export default ProductCard;
