import React from "react";
import { Link } from "react-router-dom";
import "../../styles/global-styles.css";
import "../../styles/footer.css";
import { BASIC_INFO } from "../../resources/BasicInfo";
import { DESCRIPTIONS } from "../../resources/Descriptions";
import Logo from "../../assets/images/logos/logo-212x100.jpg";
import SchoolInfo from "../reuseable/SchoolInfo";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="footer__infodiv">
          <img loading="lazy" src={Logo} alt="Gyakorlati Filozófia iskolája logo" />
          <p className="school-description">{DESCRIPTIONS.schoolDesc}</p>
        </div>
        <SchoolInfo placement="footer" />
      </div>
      <div className="footer__bottom">
        <p>© {BASIC_INFO.orgFullName}</p>
        <Link to={`/document-viewer/adatvedelem`}>Adatvédelmi irányelvek</Link>
        {" | "}
        <Link to={`/document-viewer/beszamolo`}>2021-es pénzügyi beszámoló</Link>
      </div>
      <div className="dan">
        <p>Site Full-stack and DevOps by{" "}
        <a
          href="https://github.com/InstantBuddha"
          target="_blank"
          rel="noopener noreferrer"
        >
          Daniel Marton
        </a>
        </p>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
