import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getFromCookies, setCookie } from "../utils/UseCookies";
import "../styles/global-styles.css";
import "../styles/cookie-consent.css";

function CookieConsent() {
  const [isConsentVisible, setIsConsentVisible] = useState(
    !getFromCookies("isConsentAccepted")
  );

  const acceptConsent = () => {
    setCookie("isConsentAccepted", true);
    setIsConsentVisible(false);
  };

  const consentClassName = isConsentVisible
    ? "cookie-consent-wrapper"
    : "consent-hidden";

  return (
    <div className={consentClassName}>
      <div className="cookie-consent-container">
        <p>
          Az oldal megfelelő működéséhez szükséges alapvető sütiket használunk.
        </p>
        <Link to={`/adatvedelem`}>Adatvédelmi irányelvek</Link>
      </div>
      <button className="cookie-consent-button" onClick={acceptConsent}>
        Értem
      </button>
    </div>
  );
}
export default CookieConsent;
