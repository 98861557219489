import React from "react";

export const stringFragmenter = (stringToFragment) => {
  return stringToFragment.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

export const stringToUnorderedList = (stringToSplit) => {
  const lines = stringToSplit.split("\n");
  return (
    <ul>
      {lines.map((line, index) => (
        <li key={index}>{line}</li>
      ))}
    </ul>
  );
};

export const replaceUnderscoresToSpaces = (stringToModify) => {
  return stringToModify.replaceAll(/_/g, " ");
};

export const formatDateToString = (dateToFormat) => {
  const fullDate = new Date(dateToFormat);
  const year = fullDate.getFullYear();
  const month = fullDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const day = fullDate.getDate();
  const hours = fullDate.getHours();
  const minutes = fullDate.getMinutes();

  const formattedMonth = month.toString().padStart(2, '0');
  const formattedDay = day.toString().padStart(2, '0');
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${year}.${formattedMonth}.${formattedDay} ${formattedHours}:${formattedMinutes}`;
};

export const mapTematics = (tematics) => {
  return (
    <div className="info-wrapper">
      {tematics.map((obj, index) => (
        <React.Fragment key={index}>
          <h3>{obj.title}</h3>
          <p>{obj.paragraph}</p>
        </React.Fragment>
      ))}
    </div>
  );
};
