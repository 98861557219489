import React from "react";
import { stringFragmenter, stringToUnorderedList } from "../../utils/TextUtils";

function CoursesIntro() {
  const INTRO_TEXT = `Platóntól Eckhart Tolléig sokaktól elhangzott már, hogy a tudáshoz, bölcsességhez való út nem az elme információkkal való töltögetése, sokkal inkább egyfajta visszaemlékezés, megszabadulás a téves gondolatoktól. 
  A találkozók – legyenek online vagy élőben – gondosan felépített tematikát követnek, kiemelkedő fontosságú, hogy a dialektika módszerével dolgozzuk fel a témákat. Mit is jelent ez pontosan? Az előadó szerepe, hogy átadja a beszélgetések kiindulópontjául szolgáló gondolatokat, és irányítsa a beszélgetést, így nem csak egyirányú az információ áramlása. A résztvevők nem csupán „hallgatók”, így könnyen meglehet, hogy a legfontosabb felismeréseket és kincset érő gondolatokat a többiektől hallod majd, vagy akár épp te mondod ki. Egymást fogjátok hozzásegíteni az igazság felismeréséhez.
  A bevezető kurzus után, habár roppant sok felismeréssel gazdagodsz, sok tévhittől szabadulsz meg, és az út itt korántsem ér véget. Nem kapsz róla oklevelet plecsnivel, hogy na, most már bölcs vagy. 
  Sokan már két évtized óta az iskola tagjai vagyunk, és bizony még mindig van olyan írásos anyag, amelynek közös átgondolásával és átbeszélésével haladhatunk a csoportos találkozók során. 
  A kezdő csoportok egyfajta vetésforgóban haladnak az első három témakörrel, így nem maradsz le semmiről, ha az adott időszakban nem a Bölcsességgel kezdesz!
  Álljon itt egy rövid összefoglaló az első négy év témáiról:`;

  const FIRST_BLOCK = `I. – Bölcsesség
  II. – Boldogság 
  III. – Szeretet
  IV. – Jelenlét
  V. – Szabadság
  VI – Szabadság a cselekvésben
  VII. – A szolgálat útja
  VIII. – A tudás útja
  IX. – A filozófia mint életmód
  X. – A három törvénye
  XI. – Az öt kosa
  XII. – Harmónia`;

  const SECOND_BLOCK = `M1 – Az igazság iránti vágy
  M2 – A tízrétű Dharma
  M3 – Mérték
  M4 – Szanszkára
  M5 – Platón: Szókratész védőbeszéde
  M6 – Az Antahkarana
  M7 – A kilenc pont köre
  M8 – Filozófia és nyelv`;

  const introTextWithParagraphs = stringFragmenter(INTRO_TEXT);
  const firstBlockList = stringToUnorderedList(FIRST_BLOCK);
  const secondBlockList = stringToUnorderedList(SECOND_BLOCK);

  return (
    <div className="info-wrapper">
      <hr></hr>
      <h2>Bepillantás kurzusaink témáiba</h2>
      <hr></hr>
      <p>{introTextWithParagraphs}</p>
      <h3>Az első négy év</h3>
      <div className="divider-line"></div>
      {firstBlockList}
      <h3>A következő három év</h3>
      <div className="divider-line"></div>
      {secondBlockList}
    </div>
  );
}
export default CoursesIntro;
