import React from "react";
import { useLocation, Link } from "react-router-dom";
import Thinker from "../assets/images/other/Musée_Rodin_1.jpg";
import HelmetMetaData from "./HelmetMetaData";

function NotFound404() {
  const location = useLocation();
  const notFoundDescription =
    "Mindannyian az igaz tudást keressük. Ez a keresés nem itt ér véget, mert az oldal sajnos nem található.";
  
  const uniqueHelmetMetaData = {
    title: "Gyakorlati Filozófia Iskolája | 404 - Oldal nem található",
    description: notFoundDescription,
    location: location,
  };

  return (
    <div className="page-wrapper">
    <HelmetMetaData {...uniqueHelmetMetaData} />
      <h1>404 - Oldal nem található</h1>
      <h2>Biztosan ezt kerested?</h2>
      <p>{notFoundDescription}</p>
      <Link to={"/"} className="reusable-button-link">
          Vissza a főoldalra
        </Link>
      <img className="basic-image-style"
        src={Thinker}
        alt="Auguste Rodin - Le Penseur Biztos ezt kerested? Attribution: CrisNYCa, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons"
      />
    </div>
  );
}
export default NotFound404;
