import React from "react";
import { useParams } from "react-router-dom";
import adatvedelem from "../assets/pdf/GDPR_adatkezelesi_szabalyzat.pdf";
import beszamolo from "../assets/pdf/EB00601358.pdf";

function ViewPdf() {
  let { pdfName } = useParams();
  const PDF_SOURCES = {
    adatvedelem: adatvedelem,
    beszamolo: beszamolo,
  };

  return (
    <div>
      <iframe
        src={PDF_SOURCES[pdfName]}
        title="viewPdf"
        className="view-pdf"
      ></iframe>
    </div>
  );
}
export default ViewPdf;
