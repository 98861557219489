import React from "react";
import { useLocation } from "react-router-dom";
import HelmetMetaData from "./HelmetMetaData";
import Carousel from "./home/Carousel";
import ActiveCourseSmall from "./home/ActiveCourseSmall";
import MainPoints from "./home/MainPoints";

function Home() {
  const location = useLocation();
  const uniqueHelmetMetaData = {
    title: "Gyakorlati Filozófia Iskolája | Főoldal",
    description:
      "A Gyakorlati Filozófia Iskolája főoldala. Bemutatkozás, kurzusinformáció, aktuális tanfolyamunk",
    location: location,
  };

  return (
    <div>
      <HelmetMetaData {...uniqueHelmetMetaData} />
      <Carousel />
      <div className="page-wrapper">
        <MainPoints />
        <ActiveCourseSmall />
      </div>
    </div>
  );
}

export default Home;
