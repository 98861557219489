import React from "react";
import { BASIC_INFO } from "../../resources/BasicInfo";
import "../../styles/footer.css"
import "../../styles/contact.css"
import ExternalIcons from "./ExternalIcons";

const CLASS_NAMES = {
  footer: { main: "footer__infodiv", title: "infodiv__title" },
  contact: { main: "contact__infodiv", title: "contact__title" },
};

function SchoolInfo(props) {
  const displayOrgInfo = (itemsToMap) => {
    return itemsToMap.map((infoItem) => (
      <p key={infoItem.label}>
        <strong>{infoItem.label}:</strong> {infoItem.data}
      </p>
    ));
  };

  return (
    <div className={CLASS_NAMES[props.placement].main}>
      <p className={CLASS_NAMES[props.placement].title}>{BASIC_INFO.orgFullName}</p>
      <p>
        <strong>{BASIC_INFO.orgAddress.label}:</strong>{" "}
        {BASIC_INFO.orgAddress.fullAddress()}
      </p>
      {displayOrgInfo(BASIC_INFO.contactInfo)}
      {displayOrgInfo(BASIC_INFO.legalInfo)}
      <ExternalIcons />
    </div>
  );
}

export default React.memo(SchoolInfo);
