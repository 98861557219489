import varadiProfileImage from "../assets/images/tutors/Varady-Beothy-Miklos.jpg";
import adamsProfileImage from "../assets/images/tutors/Adams-Eva.jpg";
import vamosiProfileImage from "../assets/images/tutors/Vamosi_Erika.jpg";
import simonProfileImage from "../assets/images/tutors/Simon_Katalin.jpg";

export const TUTORS_LIST = [
  {
    tutorName: "Váradi-Beöthy Miklós",
    isActive: true,
    image: varadiProfileImage,
    description: `Váradi-Beöthy Miklós az iskola vezetője és az alapítvány elnöke. Donald Lambie, a londoni iskola vezetőjének felkérésére feleségével, Patríciával hazaköltözött Magyarországra, és megalapította 2003-ban a budapesti Gyakorlati Filozófia Iskoláját. Több évtizedes előadói múlttal rendelkezik, és habár a haladó csoportok vezetésétől már visszavonult, útmutatásai valamennyi előadó és hallgató számára szabadon és kötetlenül elérhetőek.
    A tanításokról és az iskoláról így fogalmaz: „Ez a legjobb dolog, amivel életemben találkoztam. A legfontosabb útmutatást innen kapom az örök kérdés megválaszolására, hogy „Ki vagyok én?” Mert ez a kérdés: „Ki vagy mi vagyok én?”`,
  },
  {
    tutorName: "Adams Éva",
    isActive: true,
    image: adamsProfileImage,
    description: `Több a világ, mint amit képes vagyok látni belőle. Ebben gyerekkorom óta teljesen bizonyos vagyok, de ennek a bizonyosságnak a lényegéhez csak viszonylag későn, fiatal felnőttként kerültem közelebb. Pedig anyai nagyanyámmal menni kellett templomba vasárnap délelőtt a nyári szünetben, amit vele töltöttem vidéken, de nekem a vallás nem nyitott ajtót a lényegre, megmaradt az imák, énekek és váratlan helyen felállások és letérdelések formavilágában a misén. Az iskolai filozófiaoktatással hasonlóan jártam, az ontológia nem volt tananyag, vagy nem hallottam meg, hogy az.
  Nem voltam elégedetlen, és nem kutattam lázasan, hogy ki is vagyok én valójában. Biztos voltam benne, hogy nem annyi, ami látszik, és abban is, hogy ez mindenki másra is igaz. Magyarázatot ennek a kétség nélküli tudásnak a mivoltáról nem tudtam adni, ezért kerültem az erről való beszélgetést. Szabad időmben olyan dolgokkal foglalkoztam mindig – ezt csak ma fogalmazom így –, amik teljes elmélyülést tettek lehetővé. Varrtam, hímeztem, olvastam, táncoltam. Teljes odaadással.
  2007-ben találkoztam az iskolával és lassan minden megváltozott. A hozott bizonyosság megértéssel igaz tudássá nemesült, és azóta botorkálásom a világban kaland, játék, lehetőség a helyzetek meghaladására.
  2010 óta gyakorlom az előadói szerepet, a tanítások felfedezésén való közös munka a hallgatókkal erőt adó, felemelő, energetizáló.
  Szeretettel várok mindenkit, aki bizonyos, aki nem az, aki keres, aki tud vagy tudni vél, minden nyitott szívű türelmest és türelmetlent, értékes estéink lesznek együtt a virtuális osztályteremben!`,
  },
  {
    tutorName: "Vámosi Erika",
    isActive: true,
    image: vamosiProfileImage,
    description: `Hiszem, hogy nincsenek véletlenek. Hiszem, hogy minden ember, minden kapcsolódás, minden helyzet tanítást hordoz a számunkra. Volt egy nagyon kedves barátom, aki már nincs közöttünk, de talán eddigi életem legnagyobb tanítását tőle kaptam. Ő az iskola hallgatója, majd élete utolsó éveiben előadója volt.
    Az a kitartás, ahogy az iskolában tanultakat a gyakorlati életben meg akarta tapasztalni. Az a szeretet, ahogy az iskola vezetőjére, mint mesterére tekintett. Az az odaadás, ahogy az iskola életében részt vett, példamutató volt. Számomra nagyon. 
    Akkora változást idéztek elő benne az iskolában töltött évek, hogy azt mondtam: ezt én is meg akarom tapasztalni, ennek én is a részese akarok lenni. Megmutatta, milyen, amikor egy „Király" önként lemond a fizikai trónjáról, és egy szellemi katedrára lépve tanítja – nem csak verbálisan, hanem a saját példájával is – az elfogadást, az együttérzést, a megbocsájtást, az odaadást és a feltétel nélküli szeretetet. Ezért lettem én az iskola hallgatója és később az előadója.`,
  },
  {
    tutorName: "Simon Katalin",
    isActive: true,
    image: simonProfileImage,
    description: `Ki vagyok én? Az élet, természete szerint, számtalan megpróbáltatásban részesített, és mivel nem gondoltam, hogy én lennék a kiválasztott, keresni kezdtem azok társaságát akiknél válaszokat reméltem. A profilképem helyett kifejezőbb lehetne egy montázs a megszerzett tudások,elméletek, tapasztalatok, mesterek felsorolásával, de egyik sem vált egyetemes válasszá. A rászánt idő mégsem volt felesleges, hiszen a kereső útján tartottak, de már jobbat, nem többet akartam tanulni. 
    Lassan tíz éve találtam rá az iskolára. Az első mondat, amely megütötte a fülemet: „Semmit ne fogadj el, de semmit ne vess el." Szabadnak éreztem végre magam. És ez így van a mai napig. Tanulok minden lépéssel, de már az útban nincs kétségem. Tanulok hallgatóként, és az általam vezetett online csoporttól még inkább. Azonban mindezt, amit írtam ne fogadjátok el, de el se vessétek. Gyertek próbáljátok ki. Hátha útitársakra találtok Ti is!`,
  },
];
