import pointImage1 from "../assets/images/home-images/Felhok.jpg";
import pointImage2 from "../assets/images/home-images/Folyo.jpg";
import pointImage3 from "../assets/images/home-images/Szitakoto.jpg";
import pointImage4 from "../assets/images/home-images/Buza.jpg";

export const MAIN_POINTS = [
  {
    title: "Gyakorlati filozófia",
    text: `A gyakorlati filozófia alapvető célja, hogy a mindennapok forgatagában lehetővé tegye az emberek számára valódi természetük teljes potenciáljának megélését, a belső szabadság és boldogság megtapasztalását.
A körülményeink, a munkánk folyamatosan változhatnak. A szépségünk megkophat, a gazdasági körülményeink javulnak vagy romlanak. Még a legszorosabb kapcsolataink is véget érhetnek. De a bölcsesség haszna egy életen át kitart.
Iskolánk az életünk mindennapi gondjaira, lehetőségeire és az ezekkel kapcsolatban felmerült kérdésekre ad valódi, gyakorlati válaszokat. Hozzájárul az élet megértéséhez, hogy más szemmel nézzük a világot. Lehetőséget kínál, hogy a tradicionális bölcsesség a mindennapi életünk hasznára válhasson.`,
    image: pointImage1,
  },
  {
    title: "Előadásaink stílusa",
    text: `Előadásaink Szókratész tanítási módszerén alapulnak. Az egyoldalú monológok helyett a párbeszédre és saját felismerésre építünk. Így a legnagyobb bölcsességeket a melletted ülő hallgatóktól kaphatod, vagy még az is lehet, hogy tőled hallhatjuk! 
    Meggyőződésünk, hogy a bölcsesség forrása mindannyiunk számára elérhető. Mindössze arra van szükségünk, hogy kapcsolódjunk hozzá. Akár a láng, mely magától is fellobban, de fáklyáról fáklyára is átadható. Ez az oka annak, hogy előadóink az iskola hallgatói közül kerülnek ki.
    A találkozók heti rendszerességgel zajlanak, a kezdő csoportok számára jelenleg online, a témákat pedig három hónapos kurzusokra osztottuk fel.`,
    image: pointImage2,
  },
  {
    title: "Személyes átalakulás",
    text: `Az iskola tanításainak kulcspontja, a filozófia bölcsességének a hétköznapokba való átültetése. Az a célunk, hogy olyan tudást adjunk, amivel az élet viharos és megpróbáltatásokkal teli napjain is békére és boldogságra lelhetsz. 
    A talkálkozók során megannyi „Aha!” élményben lesz részed, amik már önmagukban transzformatív erejűek. Emellett már az első alkalmak egyikén megtanítunk egy figyelemgyakorlatra, mely gyakorlással, univerzális és hatékony eszközöddé válhat nem csak a hétköznapi megpróbáltatások megoldására, de az önismeret útján is.`,
    image: pointImage3,
  },
  {
    title: "Kelet és nyugat egysége",
    text: `Vizsgálódásainkat nyugatról első sorban Szókratész és Platón, keletről az advaita filozófia, továbbá az irodalom nagy alakjainak bölcsessége és a világ vallásainak gondolatai segítségével folytatjuk.
    Habár a megnyilvánult világ írtózatos ütemben tűnik változni, valódi természete – ahogy az emberé is – változatlan. Ezért Marcus Aurelius meglátásai ugyanolyan örök érvényűnek és a mai egyén számára hasznosnak bizonyulnak, mint Eckhart Tolléé.`,
    image: pointImage4,
  },
];
