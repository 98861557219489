import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "../src/styles/global-styles.css";
import Home from "./components/Home";
import Courses from "./components/courses/Courses";
import AboutUs from "./components/about-us/AboutUs";
import Blog from "./components/Blog";
import Contact from "./components/Contact";
import OnePercent from "./components/OnePercent";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import ViewPdf from "./components/ViewPdf";
import NotFound404 from "./components/NotFound404";
import CookieConsent from "./components/CookieConsent";
import Signup from "./components/courses/signup-form/Signup";
import CourseCard from "./components/courses/CourseCard";
import ScrollToTop from "./utils/ScrollToTop";
import AdminLogin from "./components/protected/admin-pages/AdminLogin";
import ProtectedRoute from "./components/protected/ProtectedRoute";
import ViewMembers from "./components/protected/admin-pages/ViewMembers";
import Tutors from "./components/about-us/Tutors";
import History from "./components/about-us/History";
import Meditation from "./components/about-us/Meditation";
import Products from "./components/about-us/Products";

function App() {
  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <Router>
        <ScrollToTop>
          <div className="App">
            <Navbar />
            <div className="mainWrapper">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/kurzusok" element={<Courses />} />
                <Route path="/jelentkezes" element={<Signup />} />
                <Route path="/rolunk" element={<AboutUs />} />
                <Route path="/rolunk/eloadoink" element={<Tutors />} />
                <Route path="/rolunk/tortenetunk" element={<History />} />
                <Route path="/rolunk/meditacio" element={<Meditation />} />
                <Route path="/rolunk/kiadvanyok" element={<Products />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/kapcsolat" element={<Contact />} />
                <Route path="/egy-szazalek" element={<OnePercent />} />
                <Route path="/document-viewer/:pdfName" element={<ViewPdf />} />
                <Route path="/bevezeto-tanfolyam" element={<CourseCard />} />
                <Route path="/login" element={<AdminLogin />} />
                <Route
                  path="/view-members"
                  element={
                    <ProtectedRoute>
                      <ViewMembers />
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<NotFound404 />} />
              </Routes>
            </div>
            <CookieConsent />
            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    </HelmetProvider>
  );
}

export default App;
