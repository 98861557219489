import { LOVE_COURSE } from "./CoursesAllInfo";

const STARTING_DATE = new Date("2024-09-09T18:00");
const OTHER_DATA = {
  dayName: `hétfőnként`,
  courseType: `Online tanfolyam`,
};

const dateFormatter = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
  };

  return date.toLocaleDateString("hu-HU", options);
};

const timeFormatter = (date) => {
  const options = { hour: "numeric", minute: "numeric" };
  return date.toLocaleTimeString("hu-HU", options);
};

export const ACTIVE_COURSE = {
  ...LOVE_COURSE,
  formattedDate: dateFormatter(STARTING_DATE),
  unformattedDate: STARTING_DATE,
  formattedTime: timeFormatter(STARTING_DATE),
  ...OTHER_DATA,
};
