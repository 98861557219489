import React, { useEffect, useState } from "react";
import "../../styles/carousel.css";
import { CAROUSEL_ITEMS } from "../../resources/CarouselItems";
import CarouselCard from "./CarouselCard";
import { ReactComponent as LeftArrow } from "../../assets/svg/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../assets/svg/right-arrow.svg";

function Carousel() {
  const [activeCardNum, setActiveCardNum] = useState(0);

  const goToNextCard = () => {
    setActiveCardNum((prevCardNum) => {
      return prevCardNum < CAROUSEL_ITEMS.length - 1 ? prevCardNum + 1 : 0;
    });
  };

  const goToPrevCard = () => {
    setActiveCardNum((prevCardNum) => {
      return prevCardNum > 0 ? prevCardNum - 1 : CAROUSEL_ITEMS.length - 1;
    });
  };

  useEffect(() => {
    const interval = setInterval(goToNextCard, 10000);
    return () => clearInterval(interval);
  }, [activeCardNum]);

  const displayIndicators = CAROUSEL_ITEMS.map((item, index) => (
    <li
      onClick={() => setActiveCardNum(index)}
      key={index}
      className={activeCardNum === index ? "active" : ""}
    ></li>
  ));

  return (
    <div>
      <div className="carousel slide">
        <ol className="carousel-indicators">{displayIndicators}</ol>
        <div className="carousel-inner">
          <CarouselCard carouselItem={CAROUSEL_ITEMS[activeCardNum]} />
        </div>
        <button className="carousel-control-prev" onClick={goToPrevCard}>
          <LeftArrow className="carousel-control-prev-icon" />
        </button>
        <button className="carousel-control-next" onClick={goToNextCard}>
          <RightArrow className="carousel-control-prev-icon" />
        </button>
      </div>
    </div>
  );
}
export default Carousel;
