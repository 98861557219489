import React from "react";
import "../../../styles/view-members.css";
import {
  formatDateToString,
  replaceUnderscoresToSpaces,
} from "../../../utils/TextUtils";

function MemberCard({ memberData }) {
  const formatValues = (key, value) => {
    if (typeof value === "boolean") {
      return value.toString();
    }
    if (key === "created_at" || key === "updated_at") {
      return formatDateToString(value);
    }
    return value;
  };

  const tableRows = Object.entries(memberData).map(([key, value]) => (
    <tr key={key}>
      <td>{replaceUnderscoresToSpaces(key)}</td>
      <td>{formatValues(key, value)}</td>
    </tr>
  ));
  
  return (
    <div className="member-card">
      <h2>{memberData.name}</h2>
      <table className="member-table">
        <thead className="thead-light">
          <tr>
            <th>Property</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    </div>
  );
}
export default MemberCard;
