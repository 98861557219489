import React from "react";
import { Link } from "react-router-dom";
import { stringFragmenter } from "../../utils/TextUtils";

function CarouselCard({ carouselItem }) {
  return (
    <div className="carousel-item">
      <img
        loading="lazy"
        className="carousel-img"
        src={carouselItem.coverImg}
        alt={carouselItem.headline}
      />
      <div className="container">
        <div className="carousel-caption">
          <h1 className="text-with-shadow">{carouselItem.headline}</h1>
          <div className="container-half-of-parent">
            <p className="text-with-shadow">{stringFragmenter(carouselItem.text)}</p>
          </div>
          <Link to={carouselItem.linkPath} className="reusable-button-link">
            Tudj meg többet
          </Link>
        </div>
      </div>
    </div>
  );
}
export default CarouselCard;
