import React from 'react'

function Blog() {
  return (
    <div className="page-wrapper">
      <h1>Hamarosan...</h1>
    </div>
  )
}

export default Blog