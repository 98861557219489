import React from "react";
import { ICON_ITEMS } from "../../resources/MenuItems";
import "../../styles/externalIcons.css";

function ExternalIcons() {
  const displayIcons = (itemsToMap) => {
    return itemsToMap.map((iconItem) => {
      const { link, displayName, component } = iconItem;
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          key={displayName}
        >
          <img loading="lazy" src={component} className="external-icon" alt="displayName" />
        </a>
      );
    });
  };

  return (
    <div className="external-icons-container">
      <div>{displayIcons(ICON_ITEMS)}</div>
    </div>
  );
}
export default ExternalIcons;
