import mindSmallImage from "../assets/images/course-images/sunset_300x147.jpg";
import mindLargeImage from "../assets/images/course-images/sunset_1100x731.jpg";

export const MIND_COURSE = {
  name: `Bevezetés a filozófiába – Az elme`,
  smallImage: mindSmallImage,
  largeImage: mindLargeImage,
  shortDesc: `Keleten és nyugaton is azt mondták a bölcsek, valódi lényünk túlmutat az elmén, aminek megfegyelmezése elengedhetetlen nem csak a hétköznapi élet egyszerű feladataiban, de az önismeret útján is. A kurzus feltárja előttünk elménk természetét és olyan módszereket sajátíthatunk el, amik mind a hétköznapi életünkben, mind az önismeret útján hatékonyan segíthetnek ebben.`,
  longDesc: `Már-már elcsépelt az idézet, miszerint „Gondolkodom, tehát vagyok” és mégis elképzelhető, hogy inkább egy alapvető félreértést fogalmaz meg, mintsem egy univerzális igazságot? Talán gondolataink olyanok lennének inkább, mint felhők az égen, amint tovaszállnak? Elképzelhető, hogy ÉN vagyok, elmémben a gondolatok pedig megjelennek, majd tovatűnnek? A kurzus során megbeszéljük, megfontoljuk mit mondanak erről és sok kapcsolódó témáról Kelet és Nyugat bölcsei sőt, gyakorlati eszközöket is kipróbálunk, amikkel elménket megzabolázhatjuk, hogy tudatosabban élhessük életünket.`,
  tematics: [
    {
      title: `1. alkalom: Hogyan lehetséges az életünket jobban a jelenben élni?`,
      paragraph: `Manapság felkapott kifejezéssé vált a mindfulness, sokan beszélnek a jelenlét fontosságáról. Miért vált egy ennyire egyszerűnek tűnő dolog, mint a jelenlét, a jelen pillanat megélése ennyire kulcsfontosságúvá? Nem csak az elmélettel foglalkozunk, gyakorlati eszközöket kapunk a jelenlétünk elmélyítésére, a valódi jelenléttel töltött idő kitágítására.`,
    },
    {
      title: `2. alkalom: Az előttünk lévő tanító és ami eltakarja előlünk a jelen pillanatot`,
      paragraph: `Valójában minden egyes életesemény felkínálja a lehetőséget, hogy a jelenben lehessünk. Miért történik mégis az, hogy bizonyos események során, mondjuk a hobbinkkal való foglalkozás közben jobban jelen tudunk maradni, mint mondjuk egy nehéz vita- vagy stresszhelyzetben?`,
    },
    {
      title: `3. alkalom: A teljes jelenlét, az abszolút és a relatív létezés`,
      paragraph: `Sokan úgy tartják, minden relatív, minden változik és az, hogy mit mondjunk igaznak, csak nézőpontunktól függ. Ugyanakkor, sem a dolgok relatív, változékony természetét, sem a nézőpontok igazságtartalmát nem lennénk képesek felfogni valami nélkül, ami állandó.`,
    },
    {
      title: `4. alkalom: A mulandó és az örök közötti különbségtétel`,
      paragraph: `Nap mint nap szembesülünk a körülöttünk lévő világ mulandó, változékony mivoltával. Az életünket nem alapozhatjuk semmire, ami mulandó, hisz ekkor kártyavárként dől össze minden. Hogyan tegyünk különbséget a mulandó és a valóban örök dolgok között?`,
    },
    {
      title: `5. alkalom: Szerepünk eljátszása a világban`,
      paragraph: `Talán elsőre meghökkenhetünk, ha ezt halljuk. De mégis máshogy viselkedünk egy munkahelyi feladat végzése során, gyermekünkkel, párunkkal. Ez helyénvaló, ha belegondolunk, hogy egy igazgató otthon viselkedne menedzserként a munkahelyén pedig apaként… Amit meg kell tanulnunk, hogy ne „égjen ránk a maszk”, le tudjuk cserélni és le is tudjuk venni ha szeretnénk.`,
    },
    {
      title: `6. alkalom: A gondolat ereje`,
      paragraph: `A gondolatnak teremtő ereje van. De akkor miért nem váltottuk valóra már most minden álmunkat? Egyáltalán boldogok lennénk, ha álmaink valóra válnának?`,
    },
    {
      title: `7. alkalom: A döntés ereje`,
      paragraph: `Amikor úgy gondolom, hogy én döntök, valójában én döntök? Vagy talán csak vágyaim, természetem, a múlt tapasztalatai rángatnak marionettként? Mi kell ahhoz, hogy képes legyek helyes döntést hozni?`,
    },
    {
      title: `8. alkalom: A szeretet ereje`,
      paragraph: `Mi a szeretet? Mi a valódi szeretet és mi az ami csak szeretetnek tűnik?`,
    },
    {
      title: `9. alkalom: Az akarat ereje`,
      paragraph: `Akarat nélkül nem érhetünk el semmit. Viszont ha túlzottan, vagy csak „nem a megfelelő módon” akarunk valamit, nem csak a saját, de mások életét is tönkretesszük. Milyen módon akarjak, hogy az boldogságot hozzon mindenki számára?`,
    },
    {
      title: `10. alkalom: Az egyéni és az egyetemes`,
      paragraph: `Vannak, akik azt mondják a bőröm választ el a külvilágtól, a világmindenségtől. Vannak akik azt mondják, épp összeköt vele. Meddig terjed az „énem”? Létezhet egy én és egy ÉN? Habár a hétköznapi életünkben nagyon ritkán tapasztaljuk meg az egységet, mégis szinte mindannyiunknak van olyan tapasztalata, amikor ez megtörtént.`,
    },
  ],
};

export const WISDOM_COURSE = {
  name: `Bevezetés a filozófiába – Bölcsesség`,
  smallImage: mindSmallImage, //do this
  largeImage: mindLargeImage,
  shortDesc: `Évezredeken keresztül a bölcsességet élőben, szemtől szemben adta át a mester a tanítványainak. Ma már az interneten is megtehetjük ugyanezt. Habár a világ állandóan változik, a valódi természetünk felismerésére vezető bölcsesség ugyanúgy igaz ma, mint évezredekkel ezelőtt. Sőt, ez a bölcsesség mindannyiunk rendelkezésére áll, mindannyian hozzáférhetünk.`,
  longDesc: `Habár a bölcsesség nem olyan dolog, hogy hétfő este végigcsinálunk egy online meetinget, és kedd reggel bölcsen (és fehér szakállal) ébredünk fel, van néhány alapvető fontosságú felismerés, amely gyökeresen változtatja meg hétköznapi életünk akár minden egyes pillanatát. A bölcsesség valójában mindannyiunk rendelkezésére áll és megvan a lehetőségünk, hogy az egyszerűbb hétköznapi szituációkban vagy akár nagy krízishelyzetekben is helyes döntést hozzunk, megfelelő lelki- és elmeállapotban maradjunk, bölcsen cselekedjünk. A tanfolyam során megismerkedünk az elme helyes használatával, a figyelem és jelenlét fontosságával és elkezdjük boncolgatni azt a kérdést is: „Ki vagyok én valójában?”.`,
  tematics: [
    {
      title: `1. alkalom: Benső bölcsesség. Filozófia és bölcsesség. Lét és tudatosság`,
      paragraph: `A filozófia szó jelentése: a bölcsesség szeretete.  A bölcsesség nagyon gyakorlatias eredménye, hogy életünket boldogabban élhetjük, mert az alapigazságok ismeretében helyes döntéseket hozunk. Ezen az alkalmon alapvető kérdéseket vizsgálunk meg, és kapunk egy nagyszerű gyakorlatot, amelyet arra használhatunk, hogy az elmét és az érzelmeket lecsendesítve visszataláljunk a jelen pillanatba.`,
    },
    {
      title: `2. alkalom: Ismerd meg önmagad!`,
      paragraph: `Nem véletlenül híres a delphoi mondás: „Ismerd meg (ön)magadat!” De, ki vagy mi vagyok ÉN valójában? Ki vagy mi nem vagyok? Vajon a testem én vagyok? Vagy inkább így kérdezve: Én mindössze csak a testem vagyok? Vagy talán az érzelmeim? Nem lehetek az érzelmeim, hiszen van, hogy legbelül „kitisztul az égbolt”, eltűnnék ha az érzelmeim és én azonosak lennének. Na és az elmém? Ha az elmém lennék csak, akkor megszűnnék, ha az elme kiüresedik a kavargó gondolatoktól…`,
    },
    {
      title: `3. alkalom: Ébrenlét, tudatossági szintek`,
      paragraph: `Ha a címben a „tudatosság mértéke” kifejezés szerepelne, mindenki könnyen ráismerne, miről is van szó valójában. Azt azért könnyen felismerjük, hogy épp ébren vagyunk-e vagy alszunk. De vajon amikor ébren vagyunk, éberek vagyunk-e? Vajon ha azt hiszem, jelen vagyok a pillanatban, valójában tényleg jelen vagyok?`,
    },
    {
      title: `4. alkalom: Jelenlét, a figyelem négy állapota`,
      paragraph: `Az Önmegismerés, a megvalósulás útján újra és újra azt találhatjuk, hogy a figyelem a kulcs. Figyelem csak egy van, mégis teljesen másnak tűnik, ha egy gyönyörű tájat nézünk a hegytetőn, vagy ha egy gombot varrunk vissza éppen a legkedvesebb kabátunkra.`,
    },
    {
      title: `5. alkalom: Platón az igazságról és az igazságtalanságról, zsarnokok és a bölcsen élt élet`,
      paragraph: `Azzal mindenki tisztában van, hogy zsarnokság alatti elnyomásban élni szörnyű, azon viszont kevesebben gondolkodnak el, hogy belső zsarnokaink – mint a harag, félelem vagy féltékenység – pokollá teszik életünket még akkor is, ha épp egy földi kánaánban vagyunk épp.`,
    },
    {
      title: `6. alkalom: Az energia három minősége. Hogyan használjuk megfelelően az energiát?`,
      paragraph: `Habár manapság már közhellyé vagy akár hókuszpókusszá alacsonyodott az energia fogalma, mégis megvannak mély, komoly filozófiai alapjai. Például, a kínai jin és jang fogalmát sokan ismerik. Ezen az alkalmon viszont egy még régebbi, az indiai védikus hagyományban használt hármas megkülönböztetéssel ismerkedünk meg.`,
    },
    {
      title: `7. alkalom: Mi az értelem? Hogyan kell használni az értelmet?`,
      paragraph: `Értelmünk használatának legmagasabb foka az állandó és a múlandó megkülönböztetése, ami valójában nem más, mint a valós megkülönböztetése a valótlantól, sőt végül a valódi Énünk felismerésének eszköze. Kapunk egy egyszerű, mégis hihetetlenül hasznos módszert, amely segít a megkülönböztetés képességének fejlesztésében.`,
    },
    {
      title: `8. alkalom: A szépség ereje`,
      paragraph: `A szépséghez mindenki vonzódik, mindenki szereti. De, vajon ha szépnek látok egy virágot, a szépség a virágból fakad, vagy a megfigyelőből, aki meglátja benne szépségét? Vagy talán létezhet valamiféle „abszolút” szépség, ami mindenben jelen van?`,
    },
    {
      title: `9. alkalom: Egység a sokféleségben, elkülönültség, az egység élménye`,
      paragraph: `Manapság már sokat hallani az egység fogalmáról arról, hogy a megvilágosodott ember megéli az egységet. Mi, hétköznapi emberek mit tehetünk, ha úgy érezzük, kiszakadtunk belőle? Egyáltalán miből fakad az elkülönültség érzésünk?`,
    },
    {
      title: `10. alkalom: Az igazság utáni vágy`,
      paragraph: `Mi másért is foglalkozhatnánk filozófiával, mintsem azért, hogy meglássuk, felismerjük az igazságot? Felmerülhet viszont a kérdés, létezik-e egyetlen, egységes igazság? Netalán létezhet-e olyan, hogy többünknek egyszerre legyen igaza, pedig máshogy vélekedünk? A nagybetűs Igazság vajon szavakba önthető-e, vagy szavaink csak olyan mértékben tükrözhetik azt vissza, mint a tó vize az esti holdat?`,
    },
  ],
};

export const LOVE_COURSE = {
  name: `Bevezetés a filozófiába – Szeretet`,
  smallImage: mindSmallImage, //do this
  largeImage: mindLargeImage,
  shortDesc: `Legbelül érezzük, hogy igaz, amikor a bölcsek azt mondják, a szeretet áthat mindent sőt, minden cselekedet legalapvetőbb okozója valójában a szeretet. Miért élünk meg mégis olyan helyzeteket, amikor a szeretet egy apró szikráját sem látjuk másokban, vagy akár önmagunkban? Miként élhetünk úgy, hogy a mindig jelen lévő szeretetet ne takarja el, fedje be, az életesemények pora?`,
  longDesc: `A szeretetet nem lehet (tanfolyamon) tanulni, hiszen lényünk része, sőt valójában minden cselekedet(ünk)et elindító legelső szikra. Szívünk kicsit olyan, mint a víz: ha tiszta és nyugodt, visszatükrözi a világ gyönyörű szépségét. Sajnos az életünkben elkerülhetetlenül megjelenő események viszont apránként megfagyaszthatják, összekarcolhatják azt, és így megjelenhet a félelem, az irigység, a harag. Tehát "megtanulni" szeretni valójában inkább egy visszaemlékezés lenne? Elvégre az összekarcolt jeget felolvasztva a víz ismét a világ tiszta tükreként szolgálhat. Persze mondani könnyebb, mint megtenni. Érdekes módon a szeretet után  „kutatva" ismét előkerül a valódi önismeret és a jelenlét fontossága is.`,
  tematics: [
    {
      title: `1. alkalom: erósz, filia és agapé`,
      paragraph: `A szeretetlenségben leélt élet üres, ebben mindenki egyetért. De vajon ugyanolyan tiszta valakinek a szeretete, ha azt különféle vágyak vagy önzés is színezik? Ha korlátozzuk egy csoportra, egyének szűk körére? Lehetséges, hogy a valódi, tiszta szeretet univerzális, és így mindenkiben, így bennünk is megvan?  Hogyan bizonyosodhatunk meg erről?`,
    },
    {
      title: `2. alkalom: Az univerzális, tiszta szeretet`,
      paragraph: `Ha felismerjük azt a tényt, hogy a szeretet mindannyiunkban ott van, sőt hogy a teremtést átható, átjáró energia, rádöbbenünk, hogy azokban a helyzetekben, amikor emberek szeretet nélküli, vagy akár gonosz cselekedeteket visznek véghez, azért történhet, mert a bennük lévő szeretetet valami elfedte, eltérítette.`,
    },
    {
      title: `3. alkalom: A szeretet mint közvetítő`,
      paragraph: `Kivel nem fordult még elő, hogy az élet leghétköznapibb helyzeteiben ránézett egy kisbabára, és az szeretettel mosolygott rá? Ha két ember bármilyen módon kapcsolatba kerül, a szeretet jelenik meg először. Nem ez lenne a hétköznapi tapasztalatunk? Nos, igen, érdemes lehet megfigyelni, vajon az elme tartalma, a vélemények és egyebek miként fedhetik el ezt.`,
    },
    {
      title: `4. alkalom: Az Upanisádok és a szeretet`,
      paragraph: `Amennyiben felismerjük, hogy a tiszta szeretet mindenhol és mindenkiben megjelenik, felmerül a kérdés, vajon mindannyiunkban van-e egy rész, amely a külső különbségektől, sőt változástól mentes lenne? Habár ez a fogalom minden régi nagy kultúrában fellelhető, ez alkalommal az Upanisádok fonalán haladunk a beszélgetéssel.`,
    },
    {
      title: `5. alkalom: A szeretet korlátozása egy szűk körre`,
      paragraph: `Hétköznapi életünkben szükséges lehet, hogy emberek egy kisebb csoportját, például a családunkat helyezzük előtérbe, de vajon milyen negatív következményekkel jár, ha szeretetünket korlátozzuk, ha valamilyen külső tulajdonság meglétéhez kötjük azt? Mit okoz az irányítás és a birtoklás vágya?`,
    },
    {
      title: `6. alkalom: A belső szerkezet`,
      paragraph: `A beszélgetést Marcus Aurelius Elmélkedések című művével indítjuk, majd átbeszélünk egy indiai filozófiában megjelenő koncepciót lényünk belső szerkezetéről, megkülönböztetvén többek között a „címkéző elmét” a megkülönböztetésre képes intelligenciától, fontos fogalmakat lefektetve, amelyek később nagy hasznunkra lesznek a beszélgetések során.`,
    },
    {
      title: `7. alkalom: A szeretet Rumi szavain keresztül és a szúfi hagyomány`,
      paragraph: `A szeretet témakörét járjuk körbe továbbra is, ezúttal Rumi, az egyik legnagyobb szúfi költő műveit feldolgozva, majd kisebb betekintést nyerhetünk abba, hogy a szúfi hagyományban miképp jelenik meg a szeretet mint fogalom.`,
    },
    {
      title: `8. alkalom: Igaz természetünk`,
      paragraph: `Habár igaz természetünk felismerése jellemzően nem egy hétköznap esti filozófiai beszélgetés eredménye, Epiktétosz és Marsilio Ficino (Mátyás király levelezőtársa, firenzei filozófus és pap) szavain keresztül körbejárjuk a témát.`,
    },
    {
      title: `9. alkalom: Munka és szeretet`,
      paragraph: `Manapság, a túlhajszolt menedzserek és munkamániások korában Kahil Gibran szavai „A munka a láthatóvá tett szeretet” első hallásra abszurdnak tűnhetnek. Elég azonban csak egy pillanatig elgondolkodni, és eszedbe jut, amikor gyermekedet pelenkázod, a rózsádat metszed, a hálószobátok falát gletteled, vagy bevásárolsz idős szüleidnek, épp ennek a megállapításnak az igazságát bizonyítod.`,
    },
    {
      title: `10. alkalom: Szeretet és tudás`,
      paragraph: `A szeretet és tudás összekapcsolása első hallásra talán furcsának tűnhet. Ellenben ha jobban átgondoljuk, képesek lettünk volna bármely tudásunkra szert tenni, ha nem szerettük volna az adott témát? A szeretet a tudás nélkül nem teljes. Az összefüggést Florence Nightingale életútján keresztül vizsgáljuk.`,
    },
  ],
};
