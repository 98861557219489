import React from "react";
import { useLocation } from "react-router-dom";
import "../styles/global-styles.css";
import CoverImage from "../assets/images/other/water_ripple.jpg";
import { BASIC_INFO } from "../resources/BasicInfo";
import HelmetMetaData from "./HelmetMetaData";

function OnePercent() {
  const location = useLocation();

  const ONE_PERCENT_CONTENT = {
    lastYear: "2022-ben",
    donorsNum: 27,
    donationValue: "242 413 Ft",
    deadline: "2023. május 22-ig",
    imageInfo: {
      thanks: "A borítókép Thiago Lazarino munkája",
      attribution:
        "Attribution: Thiago Lazarino / CC BY-SA (https://creativecommons.org/licenses/by-sa/4.0)",
    },
  };

  const OnePercentInfoBlock = (
    <div>
      <hr></hr>
      <p className="centered-info">
        <strong>
          {BASIC_INFO.orgFullName}
          <br />
          {BASIC_INFO.legalInfo[0].data}
        </strong>
      </p>
      <hr></hr>
    </div>
  );

  const uniqueHelmetMetaData = {
    title: "Gyakorlati Filozófia Iskolája | 1%",
    description:
      "A Gyakorlati Filozófia Iskolája 1%. Információ az adó 1% non-profit szervezetek számára való felajánlásához.",
    location: location,
  };

  return (
    <div className="page-wrapper wrapper-with-cover-image">
      <HelmetMetaData {...uniqueHelmetMetaData} />
      <img
        src={CoverImage}
        alt={ONE_PERCENT_CONTENT.imageInfo.attribution}
        className="cover-image"
      />
      <div className="info-wrapper">
        <h1>1% – Egy apró cseppnek is nagy hatása van</h1>
        <p>
          <strong>
            Köszönjük, hogy idén is az iskolának ajánljátok adótok 1%-át!
          </strong>
        </p>
        <p>
          Amint tudjátok, iskolánk alapítványi formában 2003-tól közhasznú
          fokozatú minősítéssel működik. Bevételeink fontos része a
          magánszemélyek személyi jövedelemadója 1 százalékának felajánlása,
          melyből származó összeget teljes egészében a működési költségekre
          fordítjuk.
        </p>
        <p>
          {`Ezúton köszönjük minden kedves adományozónak az előző évi felajánlást, ${ONE_PERCENT_CONTENT.lastYear} az 1% adományok összege `}
          <strong>{`${ONE_PERCENT_CONTENT.donorsNum} felajánlótól ${ONE_PERCENT_CONTENT.donationValue}`}</strong>
          {` volt, amit teljes mértékben az iskola fenntartási költségeire és a tanfolyamok hirdetésére fordítottunk.`}
        </p>
        <p>
          Az idén is lehetőség van arra, hogy rendelkezzetek az összevont
          adóalap után befizetett személyi jövedelemadó 1+1%-áról. Ezt legkésőbb{" "}
          <strong>{ONE_PERCENT_CONTENT.deadline}</strong> az szja-bevallástól
          függetlenül is megtehetitek.
        </p>
        {OnePercentInfoBlock}
        <p>
          A felajánlást a már jól ismert módon teheti meg minden adózó. A
          nyilatkozat legegyszerűbben elektronikusan nyújtható be az{" "}
          <a
            href="https://eszja.nav.gov.hu/app/login/#login"
            target="_blank"
            rel="noopener noreferrer"
          >
            e-SZJA webes felületen
          </a>
          , online kitöltve.
        </p>
        <p className="centered-info">
          <strong>Támogatásotokat előre is köszönjük!</strong>
        </p>
        <p>{`Kérünk, ha van olyan ismerősöd, barátod, családtagod, aki rendelkezhet az adója 1%-áról, továbbítsd neki is ezeket az információkat! A fenti összeg, amelyet ${ONE_PERCENT_CONTENT.lastYear} az 1%-os adományokból kaptunk (${ONE_PERCENT_CONTENT.donationValue}), ${ONE_PERCENT_CONTENT.donorsNum} fő felajánlásából tevődik össze. Kérjük, segítsd az iskola 1%-os adománygyűjtését Te is! Minden 1% jelentős segítség!`}</p>
        {OnePercentInfoBlock}
        <p>{ONE_PERCENT_CONTENT.imageInfo.thanks}</p>
      </div>
    </div>
  );
}

export default OnePercent;
