import React from "react";
import { stringFragmenter } from "../../../utils/TextUtils";
import { Link } from "react-router-dom";

function SubmitFeedback(props) {
  const STATUS_MESSAGES = {
    pending: `Jelentkezés küldése...`,
    success: `Sikeres jelentkezés!
    Visszaigazoló emailünk hamarosan megérkezik!`,
    failure: `Sikertelen jelentkezés.
    Próbálkozz pár perc múlva, vagy vedd fel velünk a kapcsolatot`,
  };

  return (
    <div className="page-wrapper">
      <h1>{stringFragmenter(STATUS_MESSAGES[props.status])}</h1>
      {props.errorMessage && props.status !== "pending" ? (
        <p>A rendszer hibakódja: {props.errorMessage}</p>
      ) : (
        <Link to={`/`} className="reusable-button-link">
          Vissza a főoldalra
        </Link>
      )}
    </div>
  );
}
export default SubmitFeedback;
