import React from "react";
import { Helmet } from "react-helmet-async";
import { WEBSITE_URL } from "../resources/BasicInfo";

export default function HelmetMetaData({ title, description, location }) {
  const imageUrl = `${WEBSITE_URL}/logo192x192.jpg`;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={`${WEBSITE_URL}${location.pathname}`} />
    </Helmet>
  );
}
