import React from "react";
import "../../styles/active-course-small.css";
import { Link } from "react-router-dom";
import { ACTIVE_COURSE } from "../../resources/ActiveCourse";

function ActiveCourseSmall() {
  return (
    <div className="active-course-item">
      <img
        loading="lazy"
        className="active-course-img"
        src={ACTIVE_COURSE.largeImage}
        alt={ACTIVE_COURSE.name}
      />
      <div className="active-course-caption">
        <h1 className="text-with-shadow">{ACTIVE_COURSE.name}</h1>
        <p className="text-with-shadow">{ACTIVE_COURSE.shortDesc}</p>
        <Link to={"/bevezeto-tanfolyam"} className="reusable-button-link">
          Megtekintés
        </Link>
      </div>
    </div>
  );
}
export default ActiveCourseSmall;
