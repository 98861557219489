import React from "react";
import "../../styles/reusable-card.css";
import { stringFragmenter } from "../../utils/TextUtils";

function TutorCard({ tutor }) {
  const descWithParagraphs = stringFragmenter(tutor.description)
  
  return (
    <div className="reusable-card">
      <div className="reusable-card-name">{tutor.tutorName}</div>
      <div className="divider-line"></div>
      <div className="reusable-card-details">
        <div>
          <div>
            <img
              src={tutor.image}
              alt={tutor.tutorName}
              className="reusable-card-image"
            />
          </div>
          <p className="reusable-card-description">{descWithParagraphs}</p>
        </div>
      </div>
    </div>
  );
}
export default TutorCard;
