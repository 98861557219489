import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { isWindowLandscape } from "../../utils/WindowUtils.js";
import "../../styles/navbar.css";
import "../../styles/global-styles.css";
import { MENU_ITEMS } from "../../resources/MenuItems";
import SubMenu from "./SubMenu.js";

function Navbar() {
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);
  const [isSubBarVisible, setIsSubBarVisible] = useState(false);
  const [isWindowLandscapeBool, setIsWindowLandscapeBool] = useState(
    isWindowLandscape(window.innerWidth, window.innerHeight)
  );
  const navbarRef = useRef(null);

  const toggleNav = () => {
    setIsNavbarVisible(!isNavbarVisible);
  };

  const closeNav = useCallback(() => {
    setIsNavbarVisible(false);
    setIsSubBarVisible(false);
  }, [setIsNavbarVisible, setIsSubBarVisible]);

  const handleResize = useCallback(() => {
    setIsWindowLandscapeBool(
      isWindowLandscape(window.innerWidth, window.innerHeight)
    );
  }, []);
  
  const handleClickOutside = useCallback((e) => {
    if (navbarRef.current && !navbarRef.current.contains(e.target)) {
      if (!e.target.closest(".navbar")) {
        closeNav();
      }
    }
  }, [navbarRef, closeNav]);

  const toggleSubmenu = () => {
    setIsSubBarVisible(!isSubBarVisible);
  };

  const linkItemClassname =
    isNavbarVisible || isWindowLandscapeBool ? "nav-link" : "hidden";

  const subLinkItemClassname = () => {
    if (isWindowLandscapeBool) {
      return isSubBarVisible ? "nav-link" : "hidden";
    }
    return isSubBarVisible && isNavbarVisible ? "nav-link" : "hidden";
  };

  const subMenuArrowClassname = isSubBarVisible ? "menu-item-with-sub-items-up-arrow" : "menu-item-with-sub-items-down-arrow";
  
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    document.addEventListener("click", handleClickOutside);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize, handleClickOutside]);

  const displayMenuItems = (itemsToMap) => {
    return itemsToMap.map((menuItem) => {
      const { link, displayName } = menuItem;

      if (menuItem.subItems) {
        return (
          <li key={displayName} className="li-with-submenu">
            <SubMenu
              subLinkItemClassname={subLinkItemClassname()}
              closeNav={closeNav}
              subMenuTitleName={displayName}
              subMenuTitleClassName={`${linkItemClassname} ${subMenuArrowClassname}`}
              subMenuTitleOnClick={toggleSubmenu}
              subMenuItems={menuItem.subItems}
            />
          </li>
        );
      }

      if (menuItem.filler) {
        return (
          <li key={menuItem.key} className={`${linkItemClassname} filler`}></li>
        );
      }
      return (
        <li key={displayName}>
          <Link
            to={link}
            className={`${linkItemClassname} main-nav-link`}
            onClick={closeNav}
            key={displayName}
          >
            {displayName}
          </Link>
        </li>
      );
    });
  };

  return (
    <nav className="navbar">
      <button onClick={toggleNav} className="navToggleBtn" ref={navbarRef}>
        <span className="burgerIcon"></span>
      </button>
      <Link to="/" className="nav-link homeLink" onClick={closeNav}>
        Gyakorlati Filozófia Iskolája
      </Link>
      <ul className="navbar-ul">{displayMenuItems(MENU_ITEMS)}</ul>
    </nav>
  );
}

export default React.memo(Navbar);
