import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "../../../styles/forms.css";
import SubmitFeedback from "./SubmitFeedback";
import { ACTIVE_COURSE } from "../../../resources/ActiveCourse";
import {
  FTextInput,
  FCheckbox,
  SIGN_UP_FORM_ITEMS,
  VALIDATION_MESSAGES,
} from "../../../resources/FormResources";
import { postMembersRegistration } from "../../../services/ApiServices";
import { scrollToTop } from "../../../utils/WindowUtils";

function Signup() {
  const [isSubmitStarted, setIsSubmitStarted] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [submitResponse, setSubmitResponse] = useState("pending");
  const [errorMessage, setErrorMessage] = useState(false);

  const fInitValues = SIGN_UP_FORM_ITEMS.reduce((result, obj) => {
    return { ...result, [obj.itemName]: obj.initialValue };
  }, {});

  const fValidationSchema = SIGN_UP_FORM_ITEMS.reduce((result, item) => {
    if (item.inputType === "text") {
      let schema = Yup.string()
        .max(item.max, VALIDATION_MESSAGES.max)
        .min(item.min, VALIDATION_MESSAGES.min)
        .matches(item.regExp, item.regExpWarning);

      if (item.required) {
        schema = schema.required(VALIDATION_MESSAGES.required);
      }

      return {
        ...result,
        [item.itemName]: schema,
      };
    }
    if (item.inputType === "email") {
      return {
        ...result,
        [item.itemName]: Yup.string()
          .email(item.regExpWarning)
          .min(item.min, VALIDATION_MESSAGES.min)
          .required(VALIDATION_MESSAGES.required),
      };
    }
    if (item.inputType === "checkbox") {
      let schema = Yup.boolean();

      if (item.required) {
        schema = schema
          .required(VALIDATION_MESSAGES.required)
          .oneOf([true], item.requiredWarning);
      }

      return { ...result, [item.itemName]: schema };
    }
    return {}; //just to get rid of the warning message
  }, {});

  const formInputs = SIGN_UP_FORM_ITEMS.map((item) => {
    if (item.inputType === "email" || item.inputType === "text") {
      return (
        <FTextInput
          key={item.itemName}
          label={item.required ? `${item.label} *` : item.label}
          name={item.itemName}
          placeholder={item.placeholder}
          type={item.inputType}
        />
      );
    }

    if (item.inputType === "checkbox") {
      return (
        <FCheckbox key={item.itemName} name={item.itemName}>
          {item.required ? `${item.label} *` : item.label}
        </FCheckbox>
      );
    }

    return <></>;
  });

  const prepareSubmitFeedback = (postSubmitEvent, setSubmitting) => {
    setIsSubmitStarted(true);
    setSubmitting(false);
    setSubmitResponse(postSubmitEvent);
    scrollToTop();
  };

  const handleSubmit = async (credentials, setSubmitting) => {
    const sanitizedValues = Object.fromEntries(
      Object.entries(credentials).filter(([key, value]) => value !== "")
    );
    
    setIsButtonDisabled(true);

    try {
      await postMembersRegistration(sanitizedValues);
      prepareSubmitFeedback("success", setSubmitting);
    } catch (error) {
      setSubmitting(false);
      if (error?.response?.status) {
        setErrorMessage(error.response.status);
        prepareSubmitFeedback("failure", setSubmitting);
        return;
      }
      
      setErrorMessage("Unkown error");
      prepareSubmitFeedback("failure", setSubmitting);
    }
  };

  return (
    <div className="page-wrapper">
      <h1>Jelentkezés a "{ACTIVE_COURSE.name}" kurzusra</h1>
      {isSubmitStarted ? (
        <SubmitFeedback status={submitResponse} errorMessage={errorMessage} />
      ) : (
        <Formik
          initialValues={fInitValues}
          validationSchema={Yup.object(fValidationSchema)}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting);
          }}
        >
          <Form>
            <div>A *-al jelölt elemek megadása kötelező</div>
            {formInputs}
            <div className="submit-container">
              <button type="submit" disabled={isButtonDisabled} className={`submit-btn ${isButtonDisabled ? "disabled-btn" : ""}`}>
                Jelentkezés elküldése
              </button>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
}
export default Signup;
