import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminName: "",
  isAuthenticated: false,
  token: "",
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAuthenticationStatus: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setAdminName: (state, action) => {
      state.adminName = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { setAuthenticationStatus, setAdminName, setToken } = adminSlice.actions;

export default adminSlice.reducer;
