import React from "react";

function LoginErrorMessage(props) {
  return (
    <div className="page-wrapper">
      <h1>Bejelentkezési hiba</h1>
      <p>A rendszer hibakódja: {props.errorMessage}</p>
    </div>
  );
}
export default LoginErrorMessage;
